import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import TechStackBar from "../components/techStackBar"
import Apps from "../components/apps"
import Web from "../components/web"
import PortfolioIndex from "../components/portfolioIndex"
import BlogIndex from "../components/blogIndex"
import ContentBlock from "../components/contentBlock"
import Subtitle from "../components/subtitle"
import Button from "../components/button"
// import Reviews from "../components/review"
// import Contact from "../components/contact"

class Homepage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Tenerife - Desarrollo de Aplicaciones - Diseño Web - Ecommerce - Marketing" />
        <Hero />
        <TechStackBar />
        <Apps />
        <Web />
        <ContentBlock theme="white">
          <Subtitle>Proyectos recientes</Subtitle>
          <PortfolioIndex limit={3} starred theme="light"/>
          <Button to={`/portfolio`}>Ver Portfolio</Button>
        </ContentBlock>
        <ContentBlock theme="gray">
          <Subtitle>Artículos recientes</Subtitle>
          <BlogIndex limit={3} minimal />
          <Button to={`/blog`}>Ver Blog</Button>
        </ContentBlock>
      </Layout>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
