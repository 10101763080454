import React from "react"

import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import Styled from "styled-components"

const themes = {
  white: {
    color: "#333",
    background: "linear-gradient(to right,  #fff, #eee 80%, #ececec 100%)",
    boxShadow: '0px 20px 30px rgba(50, 50, 50, 0.1)',
    boxShadowHover: '0px 30px 40px rgba(50, 50, 50, 0.8)',
  },
  grey: {
    color: "#222",
    background: "linear-gradient(to right,  #dcdcdc, #ccc 80%, #ccc 100%)",
    boxShadow: '0px 20px 30px rgba(255, 255, 255, 0.2)',
    boxShadowHover: '0px 30px 40px rgba(255, 255, 255, 0.8)',
  },
  green: {
    color: "#ccffee",
    background: "#50c0a9",
    boxShadow: '0px 20px 30px rgba(100, 255, 200, 0.2)',
    boxShadowHover: '0px 30px 40px rgba(100, 255, 200, 0.8)',
  },
}

const Wrapper = Styled.div`
  margin: ${rhythm(1)} auto;
  display: inline-block;
  width: auto;
  font-size: ${rhythm(2 / 3)};
  background: ${props => props.background};
  color: ${props => props.color};
  padding: ${rhythm(1 / 2)} ${rhythm(1)};
  font-family: ${props => props.font};
  font-weight: 600;
  text-rendering: optimizeLegibility;
  border: 0;
  box-shadow: ${props => props.boxShadow};
  border-radius: ${rhythm(1 / 5)};
  transition: all 0.25s ease-in;

  &:hover {
    filter: invert(100%);
    box-shadow: ${props => props.boxShadowHover};
    transform: scale(1.03);
  }
`
const ButtonLink = Styled(Link)`
  box-shadow: none;
  text-decoration: none;
`


const Button = ({ children, theme, font, to }) => {
  theme = theme && themes[theme] ? theme : "grey"
  font = font === "lily" ? `LilyScriptOne-Regular` : `Montserrat,sans-serif`
  return (
    <ButtonLink to={to}>
      <Wrapper
        color={themes[theme].color}
        background={themes[theme].background}
        boxShadow={themes[theme].boxShadow}
        font={font}>{children}</Wrapper>
    </ButtonLink>
  )
}

export default Button
