import React from "react"

import Styled from "styled-components"

import { device } from "../utils/device"
import { colors } from "../utils/colors"

import Title from "../components/title"

const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  flex-align: center;
  justify-content: center;
  align-items: flex-end;
  // background-image: url('/hero/feedyourweb-hero-md-1.jpg');
  background-image: url('/hero/feedyourweb-hero-md-2.jpg');
  background-origin: 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;

  @media ${device.tablet} {
    height: 300px;
  }

  @media ${device.laptop} {
    height: 400px;
  }

  @media ${device.desktop} {
    height: 400px;
  }
`

// const Image = Styled.img`
//   max-width: 300px;
//   margin: auto;
//   padding: ${rhythm(1)};
// `

const Hero = ({ children }) => {
  return (
    <Wrapper>
      <Title color={colors.midGray}>
        #Web  #Aplicaciones<br />
        #Ecommerce  #Marketing<br />
      </Title>
      {/* <Image src="/hero/hero.jpg"/> */}
    </Wrapper>
  )
}

export default Hero
