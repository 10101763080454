/**
 * Apps section component
 */

import React from "react"

import ContentBlock from "../components/contentBlock"
import Subtitle from "../components/subtitle"
import Button from "../components/button"

const Apps = () => {
  return (
    <ContentBlock theme="green" full>
      <Subtitle>Desarrollo de Aplicaciones</Subtitle>
      <p>Frontend – Backend – Apps – Plugins - Automatización - Data Mininig - Web Scrapping</p>
      <Button to="/desarrollo-aplicaciones">
          Más sobre Aplicaciones
      </Button>
    </ContentBlock>
  )
}

export default Apps
