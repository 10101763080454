import React from "react"

import { Link, useStaticQuery, graphql } from "gatsby"
import { rhythm } from "../utils/typography"

import Styled from "styled-components"

const Title = Styled.h3`
  font-size: ${rhythm(2 / 3)};
  margin-bottom: ${rhythm(1 / 24)};
  color: #333;
`
const Container = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding-bottom: ${rhythm(0.4)};
`
const BlogLink = Styled(Link)`
  color: #222;
  text-decoration: underline;
  text-decoration: none;
  box-shadow: none;
  border: none;
  transition: all 1s linear;
  h3 {
    margin-top: ${rhythm(0.9)};
  }
  &:hover {
    text-decoration: underline;
  }
`
const Excerpt = Styled.p`

`

const BlogIndex = props => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { tags: { regex: "/BLOG/" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              excerpt
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                tags
              }
            }
          }
        }
      }
    `
  )
  const posts = props.limit
    ? data.allMarkdownRemark.edges.slice(0, props.limit)
    : data.allMarkdownRemark.edges
  // console.log("blogIndex posts:", posts)

  // Minimal version
  if (props.minimal) {
    return (
      <Container>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <BlogLink to={node.fields.slug}>
                  <Title>{title}</Title>
              </BlogLink>
            </article>
          )
        })}
      </Container>
    )
  }

  return (
    <Container>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <BlogLink to={node.fields.slug}>
              <header>
                <Title>{title}</Title>
                {/* <small>{node.frontmatter.date}</small> */}
              </header>
              <section>
                <Excerpt
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </BlogLink>
          </article>
        )
      })}
    </Container>
  )
}

export default BlogIndex
