/**
 * Web section component
 */

import React from "react"

import ContentBlock from "../components/contentBlock"
import Subtitle from "../components/subtitle"
import Button from "../components/button"

const Web = () => {
  return (
    <ContentBlock theme="gray" id="diseno-paginas-web">
      <Subtitle>
        Diseño Web + Posicionamiento + Publicidad
      </Subtitle>
      <br />
      <p>
        <strong>Pack completo</strong> para iniciar un negocio en internet
      </p>
      <ul>
        <li>Diseño web responsive - pensado para móvil, tablet y desktop</li>
        <li>Páginas web que abren al instante - optimización de carga</li>
        <li>Posicionamiento de paginas web y publicidad en buscadores</li>
        <li>Alta en directorios de empresas</li>
        <li>Mantenimiento y actualizaciones</li>
      </ul>
      <Button to="/paginas-web-posicionamiento-publicidad">
        Más sobre Diseño Web & Marketing
      </Button>
      {/* <h3> */}
      {/*   <a href="/portfolio">Páginas web recientes</a> */}
      {/* </h3> */}
    </ContentBlock>
  )
}

export default Web
